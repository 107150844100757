import React, { ComponentProps } from 'react';
import { range } from 'ramda';
import Skeleton from '../Base';

export type ParagraphSkeletonProps = ComponentProps<typeof Skeleton> & {
  length?: number;
};

const ParagraphSkeleton = ({
  length = 2,
  ...props
}: ParagraphSkeletonProps) => (
  <>
    {range(0, length - 1).map((i) => (
      <Skeleton mt={2} key={i} {...props} />
    ))}
    <Skeleton mt={2} key={length} width="80%" {...props} />
  </>
);
export default ParagraphSkeleton;
