import withPavaPointTiers from './withPavaPointTiers';
import Price, {
  PriceCashAndPointsOnly,
  PriceWithCompactPointsPlusPay,
  PriceWithPointsPlusPay,
  PriceWithPointsPlusPaySlider,
} from '@/v2/components/ui/Price';

const PackagePagePrice = withPavaPointTiers(Price);
const PackagePagePriceWithPointsPlusPaySlider = withPavaPointTiers(
  PriceWithPointsPlusPaySlider,
);
const PackagePagePriceCashAndPointsOnly = withPavaPointTiers(
  PriceCashAndPointsOnly,
);
const PackagePagePriceWithPointsPlusPay = withPavaPointTiers(
  PriceWithPointsPlusPay,
);

const PackagePagePriceWithCompactPointsPlusPay = withPavaPointTiers(
  PriceWithCompactPointsPlusPay,
);

export default PackagePagePrice;
export {
  PackagePagePriceWithPointsPlusPaySlider,
  PackagePagePriceCashAndPointsOnly,
  PackagePagePriceWithPointsPlusPay,
  PackagePagePriceWithCompactPointsPlusPay,
};
