import { useMemo } from 'react';
import {
  getFirstDescendantPoOffer,
  getValidUpgradeOffers,
  sortByCheapest,
} from './utils';
import { BaseOffer, UseOfferUpgradeProps } from './types';
import { createDescendantsLookup } from '../../utils/inclusionSet';

const useOfferUpgrade = <Inclusion, Offer extends BaseOffer>({
  inclusionSets,
  selectedRoomOption,
}: UseOfferUpgradeProps<Inclusion, Offer>) => {
  const { matchingOffer, offers } = selectedRoomOption || {};

  // This should never change, so extracting into a memo so it's only ever calculated once
  const descendantsLookup = useMemo(() => {
    return createDescendantsLookup(inclusionSets);
  }, [inclusionSets]);

  return useMemo(() => {
    if (!matchingOffer) {
      return undefined;
    }

    // Is a valid upgrade option as long as it's a DIFFERENT offer that is more expensive.
    const validUpgradeOffers = getValidUpgradeOffers(offers, matchingOffer);

    if (!validUpgradeOffers?.length) {
      return undefined;
    }

    const isPoOffer = !!matchingOffer?.leadInOffer;
    const sortedOffers = sortByCheapest(validUpgradeOffers);

    // If the matching offer is a PO offer, then find the next cheapest PO offer
    // that is a descendant (if none, then no upgrade)
    if (isPoOffer) {
      const offerInclusionSetId = matchingOffer?.leadInOffer?.inclusionSetId;
      const descendants = offerInclusionSetId
        ? descendantsLookup?.get(offerInclusionSetId)
        : undefined;

      return getFirstDescendantPoOffer(sortedOffers, descendants);
    }

    // Else, the matching offer is a standard offer, then return the cheapest other offer
    return sortedOffers[0];
  }, [descendantsLookup, matchingOffer, offers]);
};

export default useOfferUpgrade;
