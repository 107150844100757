export const ALL_AIRPORTS = 'ALL_DESTINATIONS';
export const MAJOR_CITY_AIRPORTS = [
  { name: 'Adelaide', code: 'ADL' },
  { name: 'Canberra', code: 'CBR' },
  { name: 'Sydney', code: 'SYD' },
  { name: 'Darwin', code: 'DRW' },
  { name: 'Brisbane', code: 'BNE' },
  { name: 'Hobart', code: 'HBA' },
  { name: 'Melbourne', code: 'MEL', colloquialName: 'Melbourne (Tullamarine)' },
  { name: 'Perth', code: 'PER' },
];

export const AIRPORTS = [
  ...MAJOR_CITY_AIRPORTS,
  {
    name: 'Proserpine',
    code: 'PPP',
    colloquialName: 'Whitsunday Coast (Proserpine)',
  },
  { name: 'Alice Springs', code: 'ASP' },
  { name: 'Gold Coast', code: 'OOL' },
  { name: 'Kangaroo Island', code: 'KGC' },
  { name: 'Port Lincoln', code: 'PLO' },
  { name: 'Whyalla', code: 'WYA' },
  { name: 'Devonport', code: 'DPO' },
  { name: 'Mildura', code: 'MQL' },
  { name: 'Cairns', code: 'CNS' },
  { name: 'Geraldton', code: 'GET' },
  { name: 'Kalgoorlie', code: 'KGI' },
  { name: 'Karratha', code: 'KTA' },
  { name: 'Learmouth', code: 'LEA' },
  { name: 'Port Headland', code: 'PHE' },
  { name: 'Bundaberg', code: 'BDB' },
  { name: 'Coffs Harbour', code: 'CFS' },
  { name: 'Emerald', code: 'EMD' },
  { name: 'Gladstone', code: 'GLT' },
  { name: 'Hervey Bay', code: 'HVB' },
  { name: 'Mount Isa', code: 'ISA' },
  { name: 'Longreach', code: 'LRE' },
  { name: 'Mackay', code: 'MKY' },
  {
    name: 'Newcastle',
    code: 'NTL',
    colloquialName: 'Newcastle - Port Stephens',
  },
  { name: 'Port Macquarie', code: 'PQQ' },
  { name: 'Rockhampton', code: 'ROK' },
  { name: 'Tamworth', code: 'TMW' },
  { name: 'Townsville', code: 'TSV' },
  { name: 'Albury', code: 'ABX' },
  { name: 'Armidale', code: 'ARM' },
  { name: 'Bendigo', code: 'BXG' },
  { name: 'Dubbo', code: 'DBO' },
  { name: 'Merimbula', code: 'MIM' },
  { name: 'Moree', code: 'MRZ' },
  { name: 'Orange', code: 'OAG' },
  { name: 'Wagga Wagga', code: 'WGA' },
  { name: 'Launceston', code: 'LST' },
  { name: 'Hamilton Island', code: 'HTI' },
  { name: 'Broome', code: 'BME' },
  { name: 'Norfolk Island', code: 'NLK' },
  { name: 'Ayers Rock', code: 'AYQ', colloquialName: 'Uluru (Ayers Rock)' },

  { name: 'Melbourne (Avalon)', code: 'AVV' },
  { name: 'Busselton Margaret River', code: 'BQB' },
  { name: 'Sunshine Coast', code: 'MCY' },
  { name: 'Ballina Byron', code: 'BNK' },
  { name: 'Auckland', code: 'AKL' },
  { name: 'Queenstown', code: 'ZQN' },
  { name: 'Christchurch', code: 'CHC' },
  { name: 'Bali', code: 'DPS' },
  { name: 'Fiji', code: 'NAN' },
  { name: 'Phuket', code: 'HKT' },
  { name: 'Singapore', code: 'SIN' },
  { name: 'Bangkok', code: 'BKK' },
  { name: 'Hawaii', code: 'HNL' },
  { name: 'Ho Chi Minh City', code: 'SGN' },
  { name: 'Osaka', code: 'KIX' },
  { name: 'Seoul', code: 'ICN' },
  { name: 'Tokyo', code: 'NRT' },
];
