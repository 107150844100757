import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import useToggle from '@/libs/v2/hooks/useToggle';

export type SearchPanelControlsContextValue = {
  isCalendarOpen: boolean;
  openCalendar: (roomId: string | undefined | void) => void;
  closeCalendar: () => void;
  isTravellersOpen: boolean;
  openTravellers: () => void;
  closeTravellers: () => void;
  calendarRoomId: string | undefined;
  setCalendarRoomId: (roomId: string | undefined) => void;
  isMobileSearchPanelModalOpen: boolean;
  openMobileSearchPanelModal: () => void;
  closeMobileSearchPanelModal: () => void;
};

const SearchPanelControlsContext =
  createContext<SearchPanelControlsContextValue>({
    isCalendarOpen: false,
    openCalendar: () => {},
    closeCalendar: () => {},
    isTravellersOpen: false,
    openTravellers: () => {},
    closeTravellers: () => {},
    calendarRoomId: undefined,
    setCalendarRoomId: () => {},
    isMobileSearchPanelModalOpen: false,
    openMobileSearchPanelModal: () => {},
    closeMobileSearchPanelModal: () => {},
  });

const SearchPanelControlsProvider = ({ children }: { children: ReactNode }) => {
  const [calendarRoomId, setCalendarRoomId] = useState<string>();
  const {
    value: isCalendarOpen,
    setOn: openCalendarBase,
    setOff: closeCalendar,
  } = useToggle(false);

  const {
    value: isTravellersOpen,
    setOn: openTravellers,
    setOff: closeTravellers,
  } = useToggle(false);

  const {
    value: isMobileSearchPanelModalOpen,
    setOn: openMobileSearchPanelModal,
    setOff: closeMobileSearchPanelModal,
  } = useToggle(false);

  const openCalendar = useCallback(
    (roomId: string | void) => {
      setCalendarRoomId(roomId ?? undefined);
      openCalendarBase();
    },
    [openCalendarBase],
  );

  return (
    <SearchPanelControlsContext.Provider
      value={{
        isCalendarOpen,
        openCalendar,
        closeCalendar,
        isTravellersOpen,
        openTravellers,
        closeTravellers,
        calendarRoomId,
        setCalendarRoomId,
        isMobileSearchPanelModalOpen,
        openMobileSearchPanelModal,
        closeMobileSearchPanelModal,
      }}
    >
      {children}
    </SearchPanelControlsContext.Provider>
  );
};

const useSearchPanelControls = () => useContext(SearchPanelControlsContext);

export { useSearchPanelControls, SearchPanelControlsProvider };
