import { useCallback, useState } from 'react';

const useToggle = (initialValue?: boolean) => {
  const [value, setValue] = useState(initialValue ?? false);

  const toggle = useCallback(() => setValue((curr) => !curr), [setValue]);
  const setOn = useCallback(() => setValue(true), [setValue]);
  const setOff = useCallback(() => setValue(false), [setValue]);

  return {
    value,
    toggle,
    setOn,
    setOff,
  };
};

export default useToggle;
