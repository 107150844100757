import dataLayer from '@/v2/utils/dataLayer';

// Header CTA
export const mainCtaDataLayerEvent = ({ action }: { action: string }) =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `${action} - Hotel`,
    label: 'Package page',
  });

// Room CTA
export const roomCtaDataLayerEvent = ({
  action,
  roomIndex,
}: {
  action: string;
  roomIndex: number;
}) => {
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `${action} - Room ${roomIndex}`,
    label: 'Package page',
  });
};

// Search CTA
export const searchPanelCtaDataLayerEvent = ({ action }: { action: string }) =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `${action} - Search`,
    label: 'Package page',
  });

// Flying From
export const openLocationPanelDataLayerEvent = () =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `Change location`,
    label: 'Package page',
  });

// Change package option
export const openPackageOptionPanelDataLayerEvent = () =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `Change package option`,
    label: 'Package page',
  });

// 1 Other Option Events
export const viewOtherOptionsDataLayerEvent = () =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `View other options`,
    label: 'Package page',
  });

export const selectOtherPackageDataLayerEvent = () =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `Select package`,
    label: 'Package page Options popup',
  });

export const cancelOtherPackageDataLayerEvent = () =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `Cancel`,
    label: 'Package page Options popup',
  });

// Popup appeared event requested alongside Upgrade Modal Events
export const popupAppearedDataLayerEvent = () =>
  dataLayer.userInteractionsEvent({
    category: 'Popup appeared',
    action: `Upgrade your package`,
    label: 'Package page Reserve popup',
  });

// Upgrade Modal Events
export const selectUpgradePackageDataLayerEvent = () =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `Select package`,
    label: 'Package page Reserve popup',
  });

export const cancelUpgradePackageDataLayerEvent = () =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: `No Thanks`,
    label: 'Package page Reserve popup',
  });

export const otherOptionVisibleDataLayerEvent = ({
  isVisible,
}: {
  isVisible: boolean;
}) =>
  dataLayer.push({
    event: 'page_view',
    view_options_fields: isVisible ? 'TRUE' : 'FALSE',
  });
