import { groupBy } from 'ramda';
import { BEST_RATE_ID } from '../../../../constants/packages';

type BaseRoomOptions = {
  matchingLeadInOffer: any;
  matchingOffer: any;
};

export type GroupRoomOptionsProps<RoomOption extends BaseRoomOptions> = {
  roomOptions: RoomOption[] | undefined | null;
  selectedInclusionSetId: string | undefined | null;
  selectedOrigin: string | undefined | null;
};

const MAIN = 'mainOptions';
const OTHER = 'otherOptions';

const groupRoomOptions = <RoomOption extends BaseRoomOptions>({
  roomOptions,
  selectedInclusionSetId,
  selectedOrigin,
}: GroupRoomOptionsProps<RoomOption>) => {
  // No room options
  if (!roomOptions) {
    return {
      [MAIN]: null,
      [OTHER]: null,
    };
  }

  // Unfilled origin/package selection OR best rates selected
  // => all options in main
  if (
    !selectedOrigin ||
    !selectedInclusionSetId ||
    selectedInclusionSetId === BEST_RATE_ID
  ) {
    return {
      [MAIN]: roomOptions,
      [OTHER]: null,
    };
  }

  // Non best-rates package option selected
  // => rooms with matching lead in offers in 'main'
  return groupBy((roomOption) => {
    return roomOption.matchingLeadInOffer ? MAIN : OTHER;
  }, roomOptions);
};

export default groupRoomOptions;
