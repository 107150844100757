import { useEffect, useMemo } from 'react';
import useSearchQuery from '../useSearchQuery';
import {
  cheapestByOfferPrice,
  RoomWithMatchingOffer,
} from '../../utils/roomOptions';

type BaseRoomOption = RoomWithMatchingOffer & {
  roomType: {
    id: string;
  };
};

export type UseAutoSelectedRoomOptionProps<T extends BaseRoomOption> = {
  isLoading: boolean | undefined;
  isPreSearch: boolean | undefined;
  roomOptions: T[] | undefined | null;
  calendarRoomId?: string;
  setCalendarRoomId?: (roomId: string | undefined) => void;
};

const useAutoSelectedRoomOption = <T extends BaseRoomOption>({
  isLoading,
  isPreSearch,
  roomOptions,
  calendarRoomId,
  setCalendarRoomId,
}: UseAutoSelectedRoomOptionProps<T>) => {
  const { searchQuery, setSearchQuery } = useSearchQuery();
  const { room } = searchQuery || {};

  const autoSelectedRoom = useMemo(() => {
    let currentSelection = roomOptions?.find(
      (option) => option.roomType.id === room,
    );

    // Don't update selection while loading or before results are back
    if (isLoading || isPreSearch) {
      return currentSelection;
    }

    const cheapestOption = roomOptions
      ? cheapestByOfferPrice(roomOptions)
      : undefined;

    // If no selection but there are rooms available
    //  => select cheapest available option or clear selection
    const shouldUseCheapestOption = !currentSelection && cheapestOption;
    if (shouldUseCheapestOption) {
      return cheapestOption;
    }

    return currentSelection;
  }, [isLoading, isPreSearch, room, calendarRoomId, roomOptions]);

  useEffect(() => {
    const validSelectId = autoSelectedRoom?.roomType?.id;

    if (validSelectId !== room) {
      setSearchQuery({ room: validSelectId });
      setCalendarRoomId?.(validSelectId);
    }
  }, [autoSelectedRoom?.roomType?.id, room, setSearchQuery]);

  return autoSelectedRoom;
};

export default useAutoSelectedRoomOption;
