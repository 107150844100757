import { EXCLUSIVE_OFFERS_PAGE_CAMPAIGN_SLUG } from '../../constants';

const getPageNameFromPath = (path: string) => {
  if (path.split(/[?#]/)[0] === '/' || path.includes('/holidays')) {
    return 'Home Page';
  } else if (path.includes(`/${EXCLUSIVE_OFFERS_PAGE_CAMPAIGN_SLUG}`)) {
    return 'Luxury Holidays Page';
  } else if (path.includes('/deals')) {
    return 'Exclusive Offers';
  } else if (path.includes('/package')) {
    return 'Package Page';
  } else {
    return 'Destination Page';
  }
};

export default getPageNameFromPath;
