const scrollIntoView = (
  selector: string,
  options: {
    offset: number;
    behavior: ScrollBehavior;
  } = { offset: 0, behavior: 'auto' },
) => {
  const element = document.querySelector(selector);

  if (!element) {
    return;
  }

  const { offset, behavior } = options;

  window.scrollTo({
    top: element.getBoundingClientRect().top + window.scrollY + offset,
    behavior,
  });
};

export default scrollIntoView;
