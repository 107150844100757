import { groupBy, innerJoin, splitAt } from 'ramda';
import { useMemo } from 'react';
import { AIRPORTS, MAJOR_CITY_AIRPORTS } from '../../constants/airport';
import { Airport } from '../../types';

export const airportLookup = (code: string): Airport | undefined =>
  AIRPORTS.find((city) => city.code === code);

export const airportLookupFromRoute = (route: string): Airport | undefined => {
  const airportCode: string = splitAt(3, route)[0];

  return airportCode ? airportLookup(airportCode) : undefined;
};

export const useAirportLookup = () => {
  return { airportLookup };
};

const useAirports = (
  airportCodes: Array<string>,
  options?: {
    sort?: boolean;
  },
): {
  airports: Array<Airport>;
  majorCityAirports: Array<Airport>;
  otherAirports: Array<Airport>;
} => {
  let airports = useMemo(
    () =>
      innerJoin(
        (city, originCode) => city.code === originCode,
        AIRPORTS,
        airportCodes,
      ),
    [airportCodes],
  );

  if (options?.sort) {
    airports = airports.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  const capitalCodes = MAJOR_CITY_AIRPORTS.map(({ code }) => code);
  const { majorCity = [], others = [] } = groupBy(
    (airport) => (capitalCodes.includes(airport.code) ? 'majorCity' : 'others'),
    airports,
  );

  return {
    airports,
    majorCityAirports: majorCity,
    otherAirports: others,
  };
};

export default useAirports;
