/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Flex } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

interface SelectBoxProps extends Flex.BoxProps {
  active: boolean;
}

const StyledBox = styled(Flex)`
  cursor: pointer;
  border: ${(props: SelectBoxProps) =>
    props.active
      ? themeGet('components.select.borderActive')
      : themeGet('components.select.border')};
  border-radius: ${themeGet('components.select.borderRadius')};
  border-color: ${(props: SelectBoxProps) =>
    props.active
      ? themeGet('components.select.borderColorActive')
      : themeGet('components.select.borderColor')};
  background-color: ${(props: SelectBoxProps) =>
    props.active
      ? themeGet('components.select.backgroundHover')
      : themeGet('components.select.background')};
  color: ${(props: SelectBoxProps) =>
    props.active
      ? themeGet('components.select.colorHover')
      : themeGet('components.select.color')};
  margin: ${(props: SelectBoxProps) =>
    props.active
      ? themeGet('components.select.marginActive')
      : themeGet('components.select.margin')};
  font-weight: ${(props: SelectBoxProps) =>
    props.active
      ? themeGet('components.select.fontWeightHover')
      : themeGet('components.select.fontWeight')};
  font-size: ${themeGet('components.select.fontSize')};

  &:hover {
    border: ${themeGet('components.select.borderActive')};
    margin: ${themeGet('components.select.marginActive')};
    border-color: ${themeGet('components.select.borderColorActive')};
    color: ${themeGet('components.select.colorHover')};
    font-weight: ${themeGet('components.select.fontWeightHover')};
  }

  white-space: nowrap;
`;

export interface Props extends Flex.FlexProps {
  active?: boolean;
}

const Filter = ({
  active = false,
  height = '48px',
  children,
  ...props
}: Props) => {
  return (
    <StyledBox
      data-testid="FILTER"
      px={5}
      height={height}
      alignItems="center"
      active={active}
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export default Filter;
