import { createContext, ReactNode, useContext, useMemo } from 'react';
import useToggle from '@/libs/v2/hooks/useToggle';

export type SectionControls = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export type SectionControlsContextValue = {
  flyingFrom: SectionControls;
  packageOptions: SectionControls;
};

const SectionControlsContext = createContext<SectionControlsContextValue>({
  flyingFrom: {
    isOpen: false,
    open: () => {},
    close: () => {},
    toggle: () => {},
  },
  packageOptions: {
    isOpen: false,
    open: () => {},
    close: () => {},
    toggle: () => {},
  },
});

const SectionControlsProvider = ({ children }: { children: ReactNode }) => {
  const flyingFromToggle = useToggle(false);
  const packageOptionsToggle = useToggle(false);

  const value = useMemo(() => {
    return {
      flyingFrom: {
        isOpen: flyingFromToggle.value,
        open: flyingFromToggle.setOn,
        close: flyingFromToggle.setOff,
        toggle: flyingFromToggle.toggle,
      },
      packageOptions: {
        isOpen: packageOptionsToggle.value,
        open: packageOptionsToggle.setOn,
        close: packageOptionsToggle.setOff,
        toggle: packageOptionsToggle.toggle,
      },
    };
  }, [
    flyingFromToggle.setOff,
    flyingFromToggle.setOn,
    flyingFromToggle.toggle,
    flyingFromToggle.value,
    packageOptionsToggle.setOff,
    packageOptionsToggle.setOn,
    packageOptionsToggle.toggle,
    packageOptionsToggle.value,
  ]);

  return (
    <SectionControlsContext.Provider value={value}>
      {children}
    </SectionControlsContext.Provider>
  );
};

const useSectionControls = () => useContext(SectionControlsContext);

export { useSectionControls, SectionControlsProvider };
