import type { UI } from '@/v2/types';

const DEFAULT_PACKAGE_OPTION_TITLE = 'Fly & Stay Offer';
const EXCLUSIVE_OFFER_PACKAGE_OPTION_TITLE = 'Luxury Holidays Offer';

const getPackageOptionTitle = (procuredOffer: UI.ProcuredOffer) =>
  procuredOffer?.isLuxuryHolidayOffer
    ? EXCLUSIVE_OFFER_PACKAGE_OPTION_TITLE
    : DEFAULT_PACKAGE_OPTION_TITLE;

export default getPackageOptionTitle;
