import React from 'react';
import { Box, Flex, Icon, Text } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { SCREEN_SIZE } from '../../../constants';
import type { Option } from '../../../types';

const Scrollable = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${themeGet('components.select.scrollable.mobile.gap')};
  padding: ${themeGet('components.select.scrollable.mobile.padding')};
  max-height: calc(100vh - ${themeGet('space.16')} + 1px);
  overflow-y: auto;

  ${SCREEN_SIZE.MD} {
    max-height: 400px;
    gap: ${themeGet('components.select.scrollable.gap')};
    padding: ${themeGet('components.select.scrollable.padding')};
  }
`;

const StyledText = styled(Text)`
  font-weight: inherit;
`;

interface MenuItemProps {
  active: boolean;
}

const MenuItem = styled(Flex)`
  --leftBorderWidth: 4px;
  cursor: pointer;
  padding-right: ${themeGet('components.select.menuItem.paddingX')};
  border-left: var(--leftBorderWidth) solid;
  border-color: ${(props: MenuItemProps) =>
    props.active
      ? themeGet('components.select.menuItem.borderLeftColorActive')
      : 'transparent'};
  background-color: ${(props: MenuItemProps) =>
    props.active
      ? themeGet('components.select.menuItem.backgroundActive')
      : 'unset'};
  padding-left: ${(props: MenuItemProps) =>
    `calc(${themeGet('components.select.menuItem.paddingX')(
      props,
    )} - var(--leftBorderWidth))`};
  padding-top: ${themeGet('components.select.menuItem.paddingY')};
  padding-bottom: ${themeGet('components.select.menuItem.paddingY')};
  font-weight: ${(props: MenuItemProps) =>
    props.active && themeGet('components.select.menuItem.fontWeightHover')};

  &:hover {
    background-color: ${themeGet('components.select.menuItem.backgroundHover')};
  }

  color: ${themeGet('components.select.menuItem.color')};
  font-size: ${themeGet('components.select.menuItem.fontSize')};
  line-height: ${themeGet('components.select.menuItem.lineHeight')};
`;

const MenuLabel = styled(Box)`
  white-space: nowrap;
  border-bottom: 1px solid #dedede;
  font-weight: ${themeGet('components.select.menuLabel.fontWeight')};
  font-size: ${themeGet('components.select.menuLabel.fontSize')};
  line-height: ${themeGet('components.select.menuLabel.lineHeight')};
  color: ${themeGet('components.select.menuLabel.color')};
  text-align: ${themeGet('components.select.menuLabel.textAlign')};
  padding: ${themeGet('space.4')} ${themeGet('space.6')};
`;

const CloseButtonBox = styled(Box)`
  position: absolute;
  top: ${themeGet('space.4')};
  right: ${themeGet('space.6')};
`;

interface Props {
  options: Array<Option>;
  selected?: Option | null;
  menuLabel?: string;
  onMenuItemClick?: Function;
  onCloseClick?: Function;
}

const SelectMenu = ({
  options = [],
  selected,
  menuLabel = undefined,
  onMenuItemClick = undefined,
  onCloseClick = undefined,
}: Props) => (
  <>
    {menuLabel && (
      <MenuLabel position="relative">
        <Text fontSize="inherit">{menuLabel}</Text>
        <CloseButtonBox
          onClick={onCloseClick ? () => onCloseClick() : undefined}
        >
          <Icon size={24} name="close" color="icon.inclusion" />
        </CloseButtonBox>
      </MenuLabel>
    )}
    <Scrollable>
      {options.map((o, i) => (
        <MenuItem
          key={o.value}
          py={2}
          alignItems="center"
          active={o.value === selected?.value}
          onClick={
            onMenuItemClick ? (e: Event) => onMenuItemClick(e, o) : undefined
          }
        >
          <StyledText fontSize="inherit">{o.text}</StyledText>
        </MenuItem>
      ))}
    </Scrollable>
  </>
);

export default SelectMenu;
