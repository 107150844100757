import { createContext, ReactNode, useContext } from 'react';

type SkeletonContextValue = {
  isLoading: boolean;
};

type SkeletonProviderProps = {
  children?: ReactNode;
  isLoading?: boolean;
};

const SkeletonContext = createContext<SkeletonContextValue>({
  isLoading: false,
});

const SkeletonProvider = ({
  isLoading = false,
  children,
}: SkeletonProviderProps) => (
  <SkeletonContext.Provider value={{ isLoading }}>
    {children}
  </SkeletonContext.Provider>
);

const useShowSkeleton = () => {
  const { isLoading } = useContext(SkeletonContext);
  return isLoading;
};

export { SkeletonProvider, useShowSkeleton };
