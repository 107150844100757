import getImageFromCode from '@/components/Inclusion/utils/getImageFromCode';
import getIconNameFromCode from '@/components/Inclusion/utils/getIconNameFromCode';
import { Icon, Image } from '@qga/roo-ui/components';
import React from 'react';

export type InclusionIconProps = {
  code?: string;
  color?: string;
};

const InclusionIcon = ({ code, color }: InclusionIconProps) => {
  const image = getImageFromCode(code);

  if (!image) {
    const iconName = getIconNameFromCode(code);

    return <Icon name={iconName} color={color} />;
  }

  return <Image src={image} alt={code} />;
};

export default InclusionIcon;
