import { ComponentType } from 'react';
import { useRoomOptionsData } from '@/v2/components/pages/PackageView/contexts/RoomOptionsContext';
import { QFFPointTiersInstance } from '@/v2/types/ui';

const withPavaPointTiers =
  <Props,>(
    Component: ComponentType<
      Props & { tiersOverride: QFFPointTiersInstance | undefined }
    >,
  ) =>
  (props: Props) => {
    const { tiers } = useRoomOptionsData() || {};

    return <Component tiersOverride={tiers} {...props} />;
  };

export default withPavaPointTiers;
