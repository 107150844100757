import { useMemo } from 'react';
import createRoomOptions, {
  BaseLeadInOffer,
  BaseOffer,
  BaseRoomType,
  CreateRoomOptionProps,
} from './utils/createRoomOptions';
import groupRoomOptions, {
  GroupRoomOptionsProps,
} from './utils/groupRoomOptions';

export type UseGroupedRoomOptionsProps<
  Offer extends BaseOffer,
  LeadInOffer extends BaseLeadInOffer,
  RoomType extends BaseRoomType,
> = CreateRoomOptionProps<Offer, LeadInOffer, RoomType> &
  Pick<GroupRoomOptionsProps<any>, 'selectedOrigin'>;

const useGroupedRoomOptions = <
  Offer extends BaseOffer,
  LeadInOffer extends BaseLeadInOffer,
  RoomType extends BaseRoomType,
>({
  packageOffers,
  roomTypes,
  procuredOfferRooms,
  selectedInclusionSetId,
  selectedOrigin,
}: UseGroupedRoomOptionsProps<Offer, LeadInOffer, RoomType>) => {
  const roomOptions = useMemo(
    () =>
      createRoomOptions({
        packageOffers,
        roomTypes,
        procuredOfferRooms,
        selectedInclusionSetId,
      }),
    [packageOffers, procuredOfferRooms, roomTypes, selectedInclusionSetId],
  );

  const { mainOptions, otherOptions } = useMemo(
    () =>
      groupRoomOptions({
        roomOptions,
        selectedOrigin,
        selectedInclusionSetId,
      }),
    [roomOptions, selectedInclusionSetId, selectedOrigin],
  );

  return {
    all: roomOptions,
    main: mainOptions,
    other: otherOptions,
  };
};

export default useGroupedRoomOptions;
