import dataLayer from '@/v2/utils/dataLayer/dataLayer';

export const flyingFromChangeEvent = (value: string) =>
  dataLayer.searchExitFieldEvent({
    fieldValue: value,
    fieldType: 'dropdown',
    fieldName: 'Flying from',
  });

export const travellingToChangeEvent = (value: string) =>
  dataLayer.searchExitFieldEvent({
    fieldValue: value,
    fieldType: 'dropdown',
    fieldName: 'Travelling to',
  });

export const datesChangeEvent = (value: string) =>
  dataLayer.searchExitFieldEvent({
    fieldValue: value,
    fieldType: 'dropdown',
    fieldName: 'When',
  });

export const travellersChangeEvent = (value: string) =>
  dataLayer.searchExitFieldEvent({
    fieldValue: value,
    fieldType: 'dropdown',
    fieldName: 'Travellers',
  });

export const searchSubmitEvent = () => dataLayer.searchSubmitEvent();
