import PropTypes from 'prop-types';

const Total = {
  amount: PropTypes.number,
  currency: PropTypes.string,
};

const Inclusion = {
  code: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
};

const LeadInOffer = {
  id: PropTypes.string,
  promotionalSash: PropTypes.string,
  inclusions: PropTypes.arrayOf(PropTypes.shape(Inclusion)),
  title: PropTypes.string,
  description: PropTypes.string,
  minNumberOfNights: PropTypes.number,
  hasValuedAtPrice: PropTypes.bool,
  packageOffers: PropTypes.objectOf(
    PropTypes.shape({
      total: PropTypes.shape(Total),
      perTraveller: PropTypes.shape(Total),
      valuedAtTotal: PropTypes.shape(Total),
    }),
  ),
  displayTravellerTypes: PropTypes.bool,
  travellers: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    total: PropTypes.number,
  }),
  highlights: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    inclusions: PropTypes.arrayOf(PropTypes.shape(Inclusion)),
  }),
};

const Dates = {
  start: PropTypes.string,
  end: PropTypes.string,
};

const ProcuredOffer = {
  name: PropTypes.string,
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      room: PropTypes.shape({
        id: PropTypes.string,
      }),
      leadInOffers: PropTypes.arrayOf(PropTypes.shape(LeadInOffer)),
    }),
  ),
  travelDates: PropTypes.shape(Dates),
  saleDates: PropTypes.shape(Dates),
  terms: PropTypes.string,
  extended: PropTypes.bool,
};

export { LeadInOffer, ProcuredOffer as default };
