import { InclusionSet } from '../types';

// Given a set of inclusion sets generate a representation of the tree structures
// they create.
// Returns:
// - roots: An array of InclusionSets that are the roots of the trees formed by
//          the inclusion sets. NOTE: If there are a series of InclusionSets that
//          form a cyclic graph then there will be no root that connects to any
//          of those sets.
//
// - getChildren: A helper function to look up the descendants of the provided
//                InclusionSet in order to traverse down the tree.
const generateInclusionSetTrees = <I>(inclusionSets?: InclusionSet<I>[]) => {
  if (!inclusionSets) {
    return;
  }

  const roots = inclusionSets.filter((set) => !set.parentInclusionSetId);
  const childLookup = new Map<string, InclusionSet<I>[]>();
  inclusionSets.forEach((set) => {
    const { parentInclusionSetId } = set;
    if (!parentInclusionSetId) {
      return;
    }

    const parentsChildren = childLookup.get(parentInclusionSetId) || [];
    childLookup.set(parentInclusionSetId, [...parentsChildren, set]);
  });

  const getChildren = (inclusionSetOrId: InclusionSet<I> | string) => {
    const id =
      typeof inclusionSetOrId === 'string'
        ? inclusionSetOrId
        : inclusionSetOrId?.id;

    return childLookup.get(id);
  };

  return {
    roots,
    getChildren,
  };
};

export default generateInclusionSetTrees;
