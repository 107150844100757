import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { width } from 'styled-system';
import Skeleton from '../Base';

type ButtonSkeletonProps = {
  size?: 'sm';
};

const ButtonSkeleton = styled(Skeleton)<ButtonSkeletonProps>`
  height: 49px;
  width: 15rem;
  ${width}

  ${(props: ButtonSkeletonProps) =>
    props.size &&
    {
      sm: css`
        height: 41px;
      `,
    }[props.size]}
`;

export default ButtonSkeleton;
