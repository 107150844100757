import dataLayer from '@/v2/utils/dataLayer';
import { pointsValueToEventValue } from '@/libs/v2/utils/dataLayer/utils';
import { PointsValue } from '@/libs/v2/utils/dataLayer';

export const pointsToggleEvent = (value: PointsValue) => {
  const valueText = pointsValueToEventValue(value);

  if (!valueText) {
    return;
  }

  dataLayer.searchExitFieldEvent({
    fieldValue: valueText,
    fieldType: 'button',
    fieldName: 'payment type',
  });
};
