import { InclusionSet } from '../../../utils/inclusionSet/types';
import { BaseOffer } from '../types';

export const getTotal = (offer: BaseOffer) => {
  const amount = offer?.charges?.total?.amount;
  return Number(amount) ? Number(amount) : Infinity;
};

export const sortByCheapest = <Offer extends BaseOffer>(offers: Offer[]) =>
  [...offers].sort((a, b) => getTotal(a) - getTotal(b));

export const getValidUpgradeOffers = <Offer extends BaseOffer>(
  offers: Offer[] | undefined,
  matchingOffer: Offer,
) =>
  offers?.filter((offer) => {
    const isNotMatchingOffer =
      offer.hotelOffer.id != matchingOffer.hotelOffer.id;
    const isMoreExpensive = getTotal(offer) > getTotal(matchingOffer);

    return isNotMatchingOffer && isMoreExpensive;
  });

export const getFirstDescendantPoOffer = <Inclusion, Offer extends BaseOffer>(
  offers: Offer[],
  descendants: Set<InclusionSet<Inclusion>> | undefined | null,
) => {
  if (!descendants?.size) {
    return undefined;
  }

  const descendantInclusionSetIds = new Set(
    Array.from(descendants).map((inclusionSet) => inclusionSet.id),
  );

  return offers.find((offer) => {
    const offerInclusionSetId = offer?.leadInOffer?.inclusionSetId;
    return (
      offerInclusionSetId && descendantInclusionSetIds?.has(offerInclusionSetId)
    );
  });
};
