import generateInclusionSetTrees from '../generateInclusionSetTrees';
import { InclusionSet } from '../types';

// Given a list of inclusionSets (which represent a tree/s), create a lookup table
// that maps an inclusionSet id to all of its descendants in the tree.
const createDescendantsLookup = <I>(inclusionSets?: InclusionSet<I>[]) => {
  const { roots, getChildren } = generateInclusionSetTrees(inclusionSets) || {};

  if (!roots || !getChildren) {
    return;
  }

  const descendantsIdLookup = new Map<
    string,
    Set<InclusionSet<I>> | undefined
  >();

  const getDescendantsRecursive = (
    inclusionSet: InclusionSet<I>,
  ): InclusionSet<I>[] => {
    const children = getChildren(inclusionSet);
    if (!children?.length) {
      descendantsIdLookup.set(inclusionSet.id, undefined);
      return [inclusionSet];
    }

    // Get all the descendants of the current inclusion set based
    // on the descendents of its children gotten recursively
    const descendants = children.flatMap((childInclusionSet) =>
      getDescendantsRecursive(childInclusionSet),
    );
    descendantsIdLookup.set(
      inclusionSet.id,
      descendants?.length ? new Set(descendants) : undefined,
    );

    return [...descendants, inclusionSet];
  };

  roots.forEach((root) => {
    getDescendantsRecursive(root);
  });

  return descendantsIdLookup;
};

export default createDescendantsLookup;
