import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';
import {
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  lineHeight,
  borderRadius,
  display,
  space,
} from 'styled-system';

const Skeleton = styled.span`
  @keyframes skeleton {
    0% {
      background-position: -200px 0;
    }

    100% {
      background-position: calc(200px + 100%) 0;
    }
  }

  display: inline-block;
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-size: 200px 100%;
  width: 100%;
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running skeleton;

  @media (prefers-reduced-motion: reduce) {
    animation: none !important;
  }

  ${borderRadius}
  ${lineHeight}
  ${width}
  ${minWidth}
  ${maxWidth}
  ${height}
  ${minHeight}
  ${maxHeight}
  ${lineHeight}
  ${borderRadius}
  ${display}
  ${space}
`;

const BaseSkeleton = (props: ComponentProps<typeof Skeleton>) => (
  <Skeleton data-testid="SKELETON_LOADER" {...props}>
    &zwnj;
  </Skeleton>
);

export default BaseSkeleton;
