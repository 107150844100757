import useDestination from '../useDestination';
import useSearchQuery from '../useSearchQuery';

const useRoute = () => {
  const {
    searchConfig: { destinationCode },
  } = useDestination();
  const {
    searchQuery: { originCode },
  } = useSearchQuery();
  return `${originCode}${destinationCode}`;
};

export default useRoute;
