import type { UI } from '@/v2/types';
import { useMemo } from 'react';
import getPackageOptions from '@/libs/v2/utils/getPackageOptions';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';
import { getPackageOptionTitle } from '@/v2/utils/packageOption';

export type UsePackageOptionsProps = {
  procuredOffer: UI.ProcuredOffer | null;
  originCode?: string;
};
const usePackageOptions = ({ procuredOffer }: UsePackageOptionsProps) => {
  const { searchQuery } = useSearchQuery();

  const packageOptions = useMemo(() => {
    if (!procuredOffer?.saleDateRange) {
      return undefined;
    }

    return getPackageOptions({
      procuredOffer: {
        ...procuredOffer,
        saleDates: procuredOffer?.saleDateRange?.end,
      },
      title: getPackageOptionTitle(procuredOffer),
      originCode: searchQuery?.originCode,
    }).packageOptions;
  }, [searchQuery?.originCode, procuredOffer]);

  const selectedPackageOption = useMemo(() => {
    if (!searchQuery?.packageOption || !packageOptions) {
      return undefined;
    }

    return (
      packageOptions.find(
        (packageOption) => packageOption.id === searchQuery.packageOption,
      ) ?? undefined
    );
  }, [packageOptions, searchQuery?.packageOption]);

  return {
    packageOptions,
    selectedPackageOption,
  };
};

export default usePackageOptions;
