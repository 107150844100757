import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getSearchQuery, updateRouteQuery } from './utils';

const useSearchQuery = () => {
  const { query, isReady } = useRouter();
  const searchQuery = useMemo(() => getSearchQuery(query), [query]);

  return {
    searchQuery,
    setSearchQuery: updateRouteQuery,
    isReady,
  };
};
export default useSearchQuery;
