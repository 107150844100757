import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export type InViewContextValue = {
  isInView: (key: string) => boolean;
  registerElement: (key: string, element?: Element | null) => void;
  latestInView: string | null;
};

const InViewContext = createContext<InViewContextValue>({
  isInView: () => false,
  registerElement: () => {},
  latestInView: null,
});

const ScrollIntoViewContext = createContext<
  (key: string, opts?: ScrollIntoViewOptions) => void
>(() => {});

export const InViewProvider = ({ children }: { children: ReactNode }) => {
  const elementLookup = useRef(new Map<string, Element>());
  const elementReverseLookup = useRef(new Map<Element, string>());
  const [inViewElements, setInViewElements] = useState(new Set<string>());
  const intersectionObserver = useRef<IntersectionObserver>();

  useEffect(() => {
    return () => {
      intersectionObserver.current?.disconnect();
    };
  }, []);

  // Sets maintain order of entries, so we can convert to array to get last entry
  const latestInView = useMemo(() => {
    if (inViewElements.size === 0) {
      return null;
    }
    return Array.from(inViewElements.values())[inViewElements.size - 1];
  }, [inViewElements]);

  const registerElement = useCallback(
    (key: string, element?: Element | null) => {
      if (!intersectionObserver.current) {
        intersectionObserver.current = new IntersectionObserver(
          (entries) => {
            const keysToAdd: string[] = [];
            const keysToRemove: string[] = [];

            entries.forEach(({ target, isIntersecting }) => {
              const key = elementReverseLookup.current.get(target);

              if (!key) {
                return;
              }

              if (!isIntersecting) {
                keysToRemove.push(key);
              } else {
                keysToAdd.push(key);
              }
            });

            setInViewElements((inViewElements) => {
              const newInViewElements = new Set(inViewElements);
              keysToAdd.forEach((key) => newInViewElements.add(key));
              keysToRemove.forEach((key) => newInViewElements.delete(key));
              return newInViewElements;
            });
          },
          {
            rootMargin: '-70px 0px -90% 0px',
          },
        );
      }

      // End early if not all defined
      if (!key || !element || !intersectionObserver.current) {
        return;
      }
      // End early if already observing
      if (elementReverseLookup.current.get(element) === key) {
        return;
      }

      elementReverseLookup.current.set(element, key);
      elementLookup.current.set(key, element);
      intersectionObserver.current.observe(element);
    },
    [],
  );

  const isInView = useCallback(
    (key: string) => inViewElements.has(key),
    [inViewElements],
  );

  const scrollIntoView = useCallback(
    (key: string, opts?: ScrollIntoViewOptions) => {
      const element = elementLookup.current.get(key);
      element?.scrollIntoView(opts);
    },
    [],
  );

  const value = useMemo(() => {
    return {
      isInView,
      registerElement,
      latestInView,
    };
  }, [latestInView, isInView, registerElement]);

  return (
    <ScrollIntoViewContext.Provider value={scrollIntoView}>
      <InViewContext.Provider value={value}>{children}</InViewContext.Provider>
    </ScrollIntoViewContext.Provider>
  );
};

const useInViewContext = () => useContext(InViewContext);

export const useScrollIntoView = () => useContext(ScrollIntoViewContext);

export const useRegisterInViewElement = (key: string) => {
  const { registerElement } = useInViewContext();

  return useCallback(
    (element?: Element | null) => registerElement(key, element),
    [key, registerElement],
  );
};

export const useIsInView = (key: string) => {
  const scrollIntoView = useScrollIntoView();
  const { isInView, latestInView } = useInViewContext();

  const scroll = useCallback(
    (opts?: ScrollIntoViewOptions) => scrollIntoView(key, opts),
    [key, scrollIntoView],
  );

  return {
    isInView: isInView(key),
    isLatestInView: latestInView === key,
    scrollIntoView: scroll,
  };
};
