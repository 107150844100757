import { Flex, Text } from '@qga/roo-ui/components';
import InclusionIcon from './components/InclusionIcon';
import React from 'react';

export type InclusionProps = {
  inclusion: {
    name: string;
    code?: string;
    subtitle?: string | null;
  };
  showSubtitle?: boolean;
  addAndMoreText?: boolean;
  color?: string;
};

const Inclusion = ({
  inclusion,
  showSubtitle = false,
  addAndMoreText,
  color = 'greys.steel',
}: InclusionProps) => {
  const { code, name, subtitle } = inclusion;
  return (
    <Flex data-testid="INCLUSION" alignItems="flex-start" gap="3">
      <InclusionIcon code={code} color={color} />
      <Flex flexDirection="column">
        <Text fontSize="sm" color={color}>
          {name}
          {addAndMoreText ? ' and more...' : ''}
        </Text>
        {showSubtitle && subtitle && (
          <Text fontSize="2xs" color={color}>
            {subtitle}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default Inclusion;
