import { useRegisterInViewElement } from '../InViewContext';
import { ReactNode } from 'react';

export type InViewSectionProps = {
  elementKey: string;
  children?: ReactNode;
  className?: string;
};
const InViewSection = ({
  children,
  elementKey,
  className,
}: InViewSectionProps) => {
  const registerSection = useRegisterInViewElement(elementKey);

  return (
    <div
      className={className ?? ''}
      data-testid="IN_VIEW_SECTION"
      ref={registerSection}
    >
      {children}
    </div>
  );
};

export default InViewSection;
