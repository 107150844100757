import { createContext, useContext } from 'react';

export type DataLayerContextValue = {
  pageName?: string;
};

const DataLayerContext = createContext<DataLayerContextValue | undefined>(
  undefined,
);

const DataLayerProvider = DataLayerContext.Provider;

const useDataLayerContext = () => useContext(DataLayerContext);

export { useDataLayerContext, DataLayerProvider };
