import dataLayer from '@/v2/utils/dataLayer';

export const viewPackageDetailsDataLayerEvent = ({
  pageName,
  inAllOptionsModal,
}: {
  pageName: string;
  inAllOptionsModal?: boolean;
}) =>
  dataLayer.userInteractionsEvent({
    category: 'CTA Click',
    action: 'Deal details and terms',
    label: `${pageName}${inAllOptionsModal ? ' popup' : ''}`,
  });
