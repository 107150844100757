import { Flex } from '@qga/roo-ui/components';
import Inclusion from '@/v2/components/ui/Inclusion';

export type Inclusion = {
  name?: string;
  code?: string;
  subtitle?: string | null;
};

type ValidInclusion = Omit<Inclusion, 'name'> & {
  name: string;
};

export type InclusionsProps = {
  inclusions?: Inclusion[];
  showSubtitle?: boolean;
  numberToShow?: number;
  includeAndMoreText?: boolean;
  color?: string;
};

const isValidInclusion = (inclusion: Inclusion): inclusion is ValidInclusion =>
  !!inclusion.name;

const Inclusions = ({
  inclusions,
  showSubtitle = false,
  numberToShow = 4,
  includeAndMoreText = true,
  color,
}: InclusionsProps) => {
  if (!inclusions?.length) {
    return null;
  }

  const validInclusions = inclusions?.filter(isValidInclusion);
  const inclusionsToShow = Number.isInteger(numberToShow)
    ? validInclusions?.slice(0, numberToShow)
    : validInclusions;

  const hasExtraInclusions =
    validInclusions &&
    inclusionsToShow &&
    validInclusions?.length > inclusionsToShow?.length;

  return (
    <Flex gap="3" flexDirection="column">
      {inclusionsToShow.map((inclusion, index) => (
        <Inclusion
          key={index}
          showSubtitle={showSubtitle}
          inclusion={inclusion}
          addAndMoreText={
            includeAndMoreText &&
            hasExtraInclusions &&
            index === inclusionsToShow.length - 1
          }
          color={color}
        />
      ))}
    </Flex>
  );
};

export default Inclusions;
