/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { css } from '@emotion/core';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Icon, Text } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import {
  themeGet,
  ResponsiveValue,
  SpaceProps,
  HeightProps,
} from 'styled-system';
import useBreakpoints, { isScreen } from '../../hooks/useBreakpoints';
import SelectMenu from './components/SelectMenu';
import type { Option } from '../../types';
import Filter, { FilterProps } from '../Filter';
import { SCREEN_SIZE } from '../../constants';

const userSelectNone = css`
  user-select: none;
`;

const Menu = styled(Box)`
  ${userSelectNone}
  min-width: 400px;
  background-color: ${themeGet('components.select.background')};
`;

const FullScreenMenu = styled(Box)`
  ${userSelectNone}
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  border-radius: ${themeGet('components.select.fullScreenMenu.borderRadius')};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${themeGet('components.select.background')};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px;
  z-index: 999;
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: ${themeGet('colors.background.backdrop')};
  opacity: 0.8;
  z-index: 998;
`;

export const SELECT_SIZES = ['sm', 'md', 'lg'] as const;
export type SelectSize = (typeof SELECT_SIZES)[number];

interface StyledFilterProps extends FilterProps, SpaceProps, HeightProps {
  selectSize?: SelectSize;
}

/*
  We're allowing for font-size, height and padding values to be customized at point-of-use via props,
  but selectSize prop should cover all standard scenarios.
*/
const StyledFilter = styled(Filter)`
  align-items: 'center';

  ${(props: StyledFilterProps) => {
    const { selectSize = 'md', height, paddingLeft, paddingRight } = props;
    const size = `size.${selectSize}`;

    const heightDesktop =
      height || `${themeGet(`components.select.${size}.height`)(props)}`;
    const heightMobile =
      height || `${themeGet(`components.select.${size}.mobile.height`)(props)}`;
    const fontSizeDesktop = `${themeGet(`components.select.${size}.fontSize`)(
      props,
    )}`;
    const fontSizeMobile = `${themeGet(
      `components.select.${size}.mobile.fontSize`,
    )(props)}`;

    const paddingLeftDesktop =
      paddingLeft ||
      `${themeGet(`components.select.${size}.paddingLeft`)(props)}`;
    const paddingRightDesktop =
      paddingRight ||
      `${themeGet(`components.select.${size}.paddingRight`)(props)}`;
    const paddingLeftMobile =
      paddingLeft ||
      `${themeGet(`components.select.${size}.mobile.paddingLeft`)(props)}`;
    const paddingRightMobile =
      paddingRight ||
      `${themeGet(`components.select.${size}.mobile.paddingRight`)(props)}`;

    return css`
      display: flex;
      height: ${heightMobile};
      font-size: ${fontSizeMobile};
      padding-left: ${paddingLeftMobile};
      padding-right: ${paddingRightMobile};

      ${SCREEN_SIZE.MD} {
        height: ${heightDesktop};
        font-size: ${fontSizeDesktop};
        padding-left: ${paddingLeftDesktop};
        padding-right: ${paddingRightDesktop};
      }
    `;
  }}
`;

const TextWrapper = styled(Box)`
  text-overflow: ellipsis;
`;

const StyledIcon = styled(Icon)`
  width: ${themeGet('components.select.icon.size')};
  height: ${themeGet('components.select.icon.size')};
`;

interface Props extends Box.BoxProps, SpaceProps, HeightProps {
  value?: string;
  options: Array<Option>;
  placeholder?: string;
  prefix?: string;
  disabled?: boolean;
  ellipsis?: boolean;
  fontSize?: ResponsiveValue<string | number>;
  menuAlign?: 'left' | 'right';
  onSelectedChange: Function;
  selectSize?: SelectSize;
}

const Select = ({
  options,
  value,
  placeholder,
  prefix,
  onSelectedChange,
  disabled = false,
  ellipsis = false,
  fontSize = 'inherit',
  menuAlign = 'left',
  ...props
}: Props) => {
  const initOption = useMemo(
    () => options.find((o) => o.value === value),
    [options, value],
  );
  const [selected, setSelected] = useState<Option | null>(null);
  const [active, setActive] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const refMenu = useRef<HTMLElement>(null);
  const refInput = useRef<HTMLElement>(null);

  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm');

  const onInputClick = () => {
    setShowMenu(!showMenu);
  };

  const onMenuItemClick = (e: Event, o: Option) => {
    e.preventDefault();
    setShowMenu(false);
    setSelected(o);
    if (onSelectedChange) {
      onSelectedChange(o.value);
    }
  };

  const onCloseClick = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    if (initOption) {
      setSelected(initOption);
    } else {
      setSelected(null);
    }
  }, [initOption]);

  useEffect(() => {
    setActive(!!selected?.value);
  }, [selected]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (refInput.current && !refInput.current.contains(e.target as Node)) {
        if (
          refMenu.current &&
          !refMenu.current.contains(e.target as Node) &&
          !refInput.current.contains(e.target as Node)
        ) {
          if (showMenu) setShowMenu(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refMenu, refInput, showMenu]);

  const selectedText = selected
    ? prefix
      ? `${prefix} ${selected.text}`
      : `${selected.text}`
    : placeholder;

  const truncatedText =
    selectedText &&
    (selectedText.length > 15
      ? selectedText.slice(0, 14) + '...'
      : selectedText);

  return (
    <Box minWidth={0}>
      <Box ref={refInput}>
        <StyledFilter
          data-testid="SELECT_BOX"
          onClick={onInputClick}
          active={active || showMenu}
          disabled={disabled}
          {...props}
        >
          <TextWrapper
            overflow="hidden"
            width={['100%', null, ellipsis && '140px']}
            flex="1"
          >
            <Text fontWeight="inherit" fontSize={fontSize} lineHeight="24px">
              {ellipsis ? truncatedText : selectedText}
            </Text>
          </TextWrapper>
          <Box ml={2}>
            <StyledIcon name={showMenu ? 'expandLess' : 'expandMore'} />
          </Box>
        </StyledFilter>
      </Box>
      {showMenu && (
        <>
          {smallScreen ? (
            <>
              <Backdrop />
              <FullScreenMenu
                data-testid="SELECT_MENU_FULLSCREEN"
                ref={refMenu}
              >
                <SelectMenu
                  options={options}
                  selected={selected}
                  menuLabel={placeholder}
                  onMenuItemClick={onMenuItemClick}
                  onCloseClick={onCloseClick}
                />
              </FullScreenMenu>
            </>
          ) : (
            <Menu
              data-testid="SELECT_MENU"
              position="absolute"
              zIndex={999}
              ref={refMenu}
              mt={2}
              boxShadow="soft"
              borderRadius="sm"
              right={menuAlign === 'right' && 0}
            >
              <SelectMenu
                options={options}
                selected={selected}
                onMenuItemClick={onMenuItemClick}
              />
            </Menu>
          )}
        </>
      )}
    </Box>
  );
};

export default Select;
