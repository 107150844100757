import React, { ComponentType, ReactNode } from 'react';
import Base from './components/Base';
import Button from './components/Button';
import FormControl from './components/FormControl';
import Input from './components/Input';
import List from './components/List';
import Paragraph from './components/Paragraph';
import { useShowSkeleton } from './components/SkeletonContext';

type WithSkeletonProps = {
  children?: ReactNode;
  visible?: boolean;
};

const withShowSkeleton =
  <Props extends object>(SkeletonComponent: ComponentType<Props>) =>
  ({ visible, children, ...rest }: WithSkeletonProps & Props) => {
    const visibleFromContext = useShowSkeleton();
    const showSkeleton = visible ?? visibleFromContext;

    return showSkeleton ? (
      <SkeletonComponent {...(rest as Props)} />
    ) : (
      <>{children}</>
    );
  };

const Skeleton = withShowSkeleton(Base);

export default Object.assign(Skeleton, {
  Button: withShowSkeleton(Button),
  FormControl: withShowSkeleton(FormControl),
  Input: withShowSkeleton(Input),
  List: withShowSkeleton(List),
  Paragraph: withShowSkeleton(Paragraph),
});
