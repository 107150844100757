export type RoomWithMatchingLeadInOffer = {
  matchingLeadInOffer?: {
    packageOffers?: {
      [key: string]: {
        total?: {
          amount?: number;
        };
      };
    } | null;
  };
};

export type RoomWithMatchingOffer = {
  matchingOffer?: {
    charges?: {
      total?: {
        amount?: string;
      };
    };
  };
};

export const getLeadInPrice = (
  roomOption: RoomWithMatchingLeadInOffer | undefined,
  route: string,
) => {
  return (
    roomOption?.matchingLeadInOffer?.packageOffers?.[route]?.total?.amount ??
    Infinity
  );
};

export const getOfferPrice = (
  roomOption: RoomWithMatchingOffer | undefined,
) => {
  const amount = roomOption?.matchingOffer?.charges?.total?.amount;
  return Number(amount) ? Number(amount) : Infinity;
};

export const createLeadInPriceSorter =
  (route: string) =>
  (a: RoomWithMatchingLeadInOffer, b: RoomWithMatchingLeadInOffer) =>
    getLeadInPrice(a, route) - getLeadInPrice(b, route);

export const sortByOfferPrice = (
  a: RoomWithMatchingOffer,
  b: RoomWithMatchingOffer,
) => getOfferPrice(a) - getOfferPrice(b);

export const cheapestByOfferPrice = <T extends RoomWithMatchingOffer>(
  options: T[],
) =>
  options?.reduce<T | undefined>((currentCheapestOption, option) => {
    if (!option.matchingOffer) {
      return currentCheapestOption;
    }
    const optionPrice = getOfferPrice(option);
    const currentCheapestPrice = getOfferPrice(currentCheapestOption);

    return optionPrice < currentCheapestPrice ? option : currentCheapestOption;
  }, undefined);
