import LinkButton from '@/components/LinkButton';
import React, { useState } from 'react';
import type { UI } from '@/v2/types';
import PackageDetailsModal from '@/v2/components/ui/PackageDetailsModal/PackageDetailsModal';
import { Text } from '@qga/roo-ui/components';
import { Inclusion } from '@/v2/components/ui/Inclusions/Inclusions';
import Router from 'next/router';
import userInteractionEvent from '@experiences-ui/shared/utils/userInteractionEvent';
import getPageNameFromPath from '@experiences-ui/shared/utils/getPageNameFromPath';
import dataLayer from '@/v2/../../../../libs/shared/utils/dataLayer';
import { usePackagePageV2Analytics } from '@/v2/hooks/featureFlags/packagePageV2Analytics/usePackagePageV2Analytics';
import { viewPackageDetailsDataLayerEvent } from './utils/dataLayer';
import { useDataLayerContext } from '@/libs/v2/contexts/DataLayerContext';

export type PackageDetailsProps = {
  propertyContent: UI.Property;
  procuredOffer?: UI.ProcuredOffer | null;
  inclusions?: Inclusion[];
  inAllOptionsModal?: boolean;
};

const PackageDetails = ({
  propertyContent,
  procuredOffer,
  inclusions,
  inAllOptionsModal,
}: PackageDetailsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const shouldUseNewAnalytics = usePackagePageV2Analytics();
  const { pageName } = useDataLayerContext() || {};

  if (!procuredOffer || !inclusions) {
    return null;
  }

  const { destination, name } = propertyContent;

  const onShowDetailsAndTerms = () => {
    shouldUseNewAnalytics && !!pageName
      ? viewPackageDetailsDataLayerEvent({
          pageName,
          inAllOptionsModal,
        })
      : dataLayer.push(
          userInteractionEvent(
            getPageNameFromPath(Router.asPath),
            `View Package Details Link Selected`,
            'View Package Details',
          ),
        );
    setIsModalOpen(true);
  };

  return (
    <>
      {/* @ts-ignore - bad type from JS*/}
      <LinkButton
        data-testid="PACKAGE_DETAILS_BUTTON"
        onClick={onShowDetailsAndTerms}
      >
        <Text
          bold
          link
          fontSize={['xs', null, 'sm']}
          textDecoration="underline"
        >
          View package details and terms
        </Text>
      </LinkButton>
      {isModalOpen && (
        <PackageDetailsModal
          onClose={() => setIsModalOpen(false)}
          destinationName={destination.title}
          propertyName={name}
          terms={procuredOffer.terms ?? ''}
          inclusions={inclusions}
        />
      )}
    </>
  );
};

export default PackageDetails;
