import {
  CreateRoomOptionProps,
  BaseRoomType,
  BaseOffer,
  BaseLeadInOffer,
} from './types';
import { BEST_RATE_ID } from '../../../../constants/packages';

const getTotal = (offer: BaseOffer) => {
  const amount = offer?.charges?.total?.amount;
  return Number(amount) ? Number(amount) : Infinity;
};

const createRoomOptions = <
  Offer extends BaseOffer,
  LeadInOffer extends BaseLeadInOffer,
  RoomType extends BaseRoomType,
>({
  packageOffers,
  roomTypes,
  procuredOfferRooms,
  selectedInclusionSetId,
}: CreateRoomOptionProps<Offer, LeadInOffer, RoomType>) => {
  const isBestRates = selectedInclusionSetId === BEST_RATE_ID;
  const roomTypeIdToOffers = new Map(
    packageOffers?.map((packageOffer) => [
      packageOffer.roomTypeId,
      packageOffer.offers,
    ]),
  );

  const roomTypeIdToLeadInOffers = new Map(
    procuredOfferRooms?.map((room) => [room.room.id, room.leadInOffers]),
  );

  return roomTypes?.map((roomType) => {
    const leadInOffersForRoom = roomTypeIdToLeadInOffers.get(roomType.id);
    const leadInOffersLookup = new Map(
      leadInOffersForRoom?.map((leadInOffer) => [leadInOffer.id, leadInOffer]),
    );
    const offersForRoom = roomTypeIdToOffers.get(roomType.id)?.map((offer) => {
      const leadInOffer = leadInOffersLookup.get(offer.hotelOffer.id);
      return {
        ...offer,
        leadInOffer,
      };
    });

    const leadInOfferMatchingSelectedInclusionSet = leadInOffersForRoom?.find(
      (leadInOffer) => leadInOffer.inclusionSetId === selectedInclusionSetId,
    );

    const offerMatchingSelectedInclusionSet =
      leadInOfferMatchingSelectedInclusionSet
        ? offersForRoom?.find((offer) => {
            return (
              offer.hotelOffer.id ===
              leadInOfferMatchingSelectedInclusionSet?.id
            );
          })
        : undefined;

    const cheapestOffer = isBestRates
      ? offersForRoom?.reduce((currentBestOffer, offer) => {
          if (!currentBestOffer) {
            return offer;
          }
          if (getTotal(offer) < getTotal(currentBestOffer)) {
            return offer;
          }
          return currentBestOffer;
        })
      : undefined;

    return {
      roomType,
      offers: offersForRoom,
      leadInOffers: leadInOffersForRoom,
      matchingOffer: isBestRates
        ? cheapestOffer
        : offerMatchingSelectedInclusionSet,
      matchingLeadInOffer: leadInOfferMatchingSelectedInclusionSet,
    };
  });
};

export default createRoomOptions;
