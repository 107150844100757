import React, { ComponentProps } from 'react';
import {
  ListItem as BaseListItem,
  List as BaseList,
} from '@qga/roo-ui/components';
import { range } from 'ramda';
import Skeleton from '../Base';
import styled from '@emotion/styled';

export type ListSkeletonProps = ComponentProps<typeof Skeleton> & {
  length?: number;
};

const List = styled(BaseList)`
  padding-left: 0;
`;

const ListItem = styled(BaseListItem)`
  list-style-type: none;
`;

const ListSkeleton = ({ length = 2, ...props }: ListSkeletonProps) => (
  <List>
    {range(0, length).map((i) => (
      <ListItem key={i}>
        <Skeleton {...props} />
      </ListItem>
    ))}
  </List>
);
export default ListSkeleton;
