import { differenceInDays, endOfDay } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { MEDIUM_DATE_FORMAT, TIMEZONE } from '../../constants';
import { DateRange } from '../../types';

interface Props {
  dateRange: DateRange;
  extended?: boolean;
}

const getCampaignEndMessage = ({
  dateRange,
  extended = false,
}: Props): string | null => {
  if (!dateRange?.utc) return null;

  const date = utcToZonedTime(new Date(dateRange.utc), TIMEZONE);
  const now = utcToZonedTime(new Date(), TIMEZONE);
  const daysUntilSaleEnd = differenceInDays(endOfDay(date), endOfDay(now));
  const formatDate = formatInTimeZone(
    new Date(dateRange.utc),
    TIMEZONE,
    MEDIUM_DATE_FORMAT,
  );

  if (daysUntilSaleEnd < 0) return null;
  if (daysUntilSaleEnd < 1) return 'ENDS TONIGHT';
  if (daysUntilSaleEnd < 2) return 'ENDS TOMORROW';
  if (daysUntilSaleEnd < 3) return 'ENDS IN 2 DAYS';
  if (daysUntilSaleEnd < 4) return 'ENDS IN 3 DAYS';
  if (extended) return `EXTENDED TO ${formatDate}`;

  return `ENDS ${formatDate}`;
};

export default getCampaignEndMessage;
