import { Box } from '@qga/roo-ui/components';
import React, { ComponentProps } from 'react';
import Skeleton from '../Base';
import InputSkeleton from '../Input';

const FormControlSkeleton = (props: ComponentProps<typeof Box>) => (
  <Box {...props}>
    <Skeleton width="5rem" />
    <Box mt="3px">
      <InputSkeleton />
    </Box>
  </Box>
);

export default FormControlSkeleton;
