import { PackageOffer } from '../../../../../types/client/ProcuredOffer';

const getCheapestPackageOfferTotal = (
  packageOffers: Record<string, PackageOffer> | null,
) => {
  if (!packageOffers || !Object.values(packageOffers)?.length) {
    return Infinity;
  }

  const allTotals = Object.values(packageOffers).map(
    (packageOffer) => packageOffer?.total?.amount ?? Number.POSITIVE_INFINITY,
  );

  return Math.min(...allTotals);
};

export default getCheapestPackageOfferTotal;
