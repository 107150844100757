import { compose, groupBy, keys, map, omit, pipe, sort, when } from 'ramda';
import type {
  Inclusion,
  LeadInOffer,
  PackageOffer,
  ProcuredOffer,
} from '../../../types/client/ProcuredOffer';
import { getCampaignEndMessage } from '../getCampaignEndMessage';
import { airportLookupFromRoute } from '../../hooks/useAirports';
import { Airport } from '@experiences-ui/shared/v2/types';
import { getCheapestPackageOfferTotal } from './utils';

const DEFAULT_PACKAGE_OPTION_TITLE = 'Holidays Exclusive Deal';

type CheapestRoute = {
  price: PackageOffer | undefined;
  origin: Airport | undefined;
  destination: Airport | undefined;
  nights: number;
  travellers: LeadInOffer['travellers'];
  displayTravellerTypes: boolean | undefined;
};

const getCheapestRoute = ({
  leadInOffer,
  originCode,
  destinationCode,
}: {
  leadInOffer: LeadInOffer;
  originCode?: string;
  destinationCode?: string;
}): CheapestRoute => {
  if (originCode && destinationCode) {
    const route = `${originCode}${destinationCode}`;
    const price = leadInOffer?.packageOffers?.[route];

    return {
      price,
      origin: airportLookupFromRoute(originCode),
      destination: airportLookupFromRoute(destinationCode),
      nights: leadInOffer.minNumberOfNights,
      travellers: leadInOffer.travellers,
      displayTravellerTypes: leadInOffer.displayTravellerTypes,
    };
  }

  const { packageOffers } = leadInOffer;
  const packageOffersArray = compose(
    map((key) => ({ route: key, ...packageOffers![key] })),
    keys,
  )(packageOffers);

  const cheapestOrigin = packageOffersArray.sort(
    byCheapestPrice((offer: PackageOffer) => offer.perTraveller?.amount),
  )[0];

  return {
    origin: cheapestOrigin && airportLookupFromRoute(cheapestOrigin.route),
    destination: destinationCode
      ? airportLookupFromRoute(destinationCode)
      : undefined,
    price: {
      ...omit(['route'], cheapestOrigin),
    },
    nights: leadInOffer.minNumberOfNights,
    travellers: leadInOffer.travellers,
    displayTravellerTypes: leadInOffer.displayTravellerTypes,
  };
};

const byCheapestPrice =
  <I>(getPriceAmount: Function) =>
  (left: I, right: I) => {
    const rightAmount = getPriceAmount(right) ?? Number.POSITIVE_INFINITY;
    const leftAmount = getPriceAmount(left) ?? Number.POSITIVE_INFINITY;

    return leftAmount - rightAmount;
  };

export interface CheapestOffer {
  leadInOffer: LeadInOffer;
  cheapestRoute: CheapestRoute;
}

export interface PackageOption {
  id: string;
  title: string;
  inclusions: Inclusion[];
  endDateMessage: string | null;
  cheapestOffer: CheapestOffer;
  optionNumber: number;
}

const getPackageOptions = ({
  procuredOffer,
  originCode,
  title = DEFAULT_PACKAGE_OPTION_TITLE,
  commonInclusions,
}: {
  procuredOffer: Pick<
    ProcuredOffer,
    'rooms' | 'destinationCode' | 'extended' | 'saleDates'
  > | null;
  originCode?: string;
  title?: string;
  commonInclusions?: Inclusion[];
}) => {
  if (!procuredOffer?.rooms) return { packageOptions: [] };

  const leadInOffers =
    procuredOffer.rooms
      .flatMap((room) => room.leadInOffers)
      .filter(({ inclusionSetId }) => inclusionSetId) ?? [];

  if (!leadInOffers) {
    return { packageOptions: [] };
  }

  const groupedOffers = groupBy(
    (offer) => offer.inclusionSetId ?? 'noInclusionSetId',
    leadInOffers,
  );

  Object.values(groupedOffers).forEach((leadInOffers) =>
    leadInOffers.sort(
      byCheapestPrice(
        (offer: LeadInOffer) =>
          // Qantas does not have offerTotal, so we sort by cheapest package offer.
          offer.offerTotal?.amount ??
          getCheapestPackageOfferTotal(offer.packageOffers),
      ),
    ),
  );
  const packageOptions = Object.entries(groupedOffers).map(
    ([inclusionSetId, sortedLeadInOffers]): PackageOption => {
      const leadInOffer = sortedLeadInOffers[0];
      const destinationCode = procuredOffer?.destinationCode;

      const pillText =
        procuredOffer &&
        getCampaignEndMessage({
          dateRange: procuredOffer.saleDates,
          extended: procuredOffer.extended,
        });

      const inclusions = [
        ...(commonInclusions ?? []),
        ...leadInOffer.inclusions,
      ];

      return {
        id: inclusionSetId,
        title,
        inclusions,
        endDateMessage: pillText,
        optionNumber: 1, // Will be immediately changed when sorted
        cheapestOffer: {
          leadInOffer,
          cheapestRoute: getCheapestRoute({
            originCode,
            destinationCode,
            leadInOffer,
          }),
        },
      };
    },
  );

  const sortedPackageOptions = pipe(
    sort<PackageOption>(
      byCheapestPrice(
        (option: PackageOption) =>
          option.cheapestOffer?.cheapestRoute?.price?.total?.amount,
      ),
    ),
    when(
      (packageOptions) => packageOptions.length > 1,
      (packageOptions: PackageOption[]) =>
        packageOptions.map((packageOption, index) => ({
          ...packageOption,
          title: `${packageOption.title} ${index + 1}`,
          optionNumber: index + 1,
        })),
    ),
  )(packageOptions);

  return { packageOptions: sortedPackageOptions };
};

export default getPackageOptions;
