import React from 'react';
import { Flex, Heading } from '@qga/roo-ui/components';
import type { UI } from '@/v2/types';
import InclusionsList from '@/v2/components/ui/Inclusions';
import PackageDetails from '@/v2/components/ui/PackageDetails';
import { Inclusion } from '@/v2/components/ui/Inclusions/Inclusions';

export type PackageInclusionsProps = {
  propertyContent: UI.Property;
  procuredOffer?: UI.ProcuredOffer | null;
  inclusions: Inclusion[];
  inAllOptionsModal?: boolean;
  color?: string;
};

const PackageInclusions = ({
  inclusions,
  procuredOffer,
  propertyContent,
  inAllOptionsModal,
  color = 'text.body',
}: PackageInclusionsProps) => {
  return inclusions?.length > 0 ? (
    <Flex
      data-testid="INCLUSIONS"
      alignItems="flex-start"
      flexDirection="column"
      gap="3"
    >
      <Heading.h3 color={color} fontWeight="normal" fontSize="sm" mb="0">
        Package inclusions:
      </Heading.h3>
      <InclusionsList inclusions={inclusions} color={color} />
      <PackageDetails
        propertyContent={propertyContent}
        procuredOffer={procuredOffer}
        inclusions={inclusions}
        inAllOptionsModal={inAllOptionsModal}
      />
    </Flex>
  ) : null;
};

export default PackageInclusions;
